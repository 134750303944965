<template>
  <v-card
    elevation="12"
    :class="{
      'v-card__chart': true,
      'v-card__chart--has-content': !!$scopedSlots.default && !noData && !error,
      'v-card__chart--full-width': fullWidth,
      mobile: isMobile
    }"
  >
    <v-card-title class="mb-4">
      <v-row justify="space-between" class="break-word" dense>
        <v-col cols="auto" class="text-h3 semi-bold">
          {{ $te(title) ? $t(title) : title }}
        </v-col>
        <v-col v-if="$scopedSlots['title-menu'] && !noData" cols="auto">
          <div class="title-menu__wrapper">
            <slot name="title-menu"></slot>
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text :class="{ mobile: isMobile }">
      <app-alert v-if="error" class="my-4" />
      <div v-else-if="noData" class="subtitle-1 my-4">{{ $t('dashboard.noData') }}</div>
      <template v-else>
        <slot>
          <div class="fill-height d-flex justify-center align-center">
            <app-spinner />
          </div>
        </slot>
        <v-row
          v-if="isFactoring && filterPurchaseStatus && $scopedSlots.default"
          class="purchase-filter-row"
          dense
          :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'end'"
        >
          <v-col
            v-for="item in purchaseStatusFilterItems"
            :key="item.value"
            cols="auto"
            class="d-flex justify-center align-stretch"
          >
            <app-btn
              :class="{ 'v-btn--select': true, active: item.value === selectedPurchaseStatus }"
              @click="selectedPurchaseStatus = item.value"
            >
              {{ item.label }}
            </app-btn>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ChartCard',

  props: {
    error: {
      type: Boolean,
      default: false
    },
    filterPurchaseStatus: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selectedPurchaseStatus: 'all',
      purchaseStatusFilterItems: [
        {
          label: this.$t('all'),
          value: 'all'
        },
        {
          label: this.$t('vouchers.purchaseStatusOptions.purchased'),
          value: 'purchased'
        },
        {
          label: this.$t('vouchers.purchaseStatusOptions.notPurchased'),
          value: 'notPurchased'
        }
      ]
    };
  },

  watch: {
    selectedPurchaseStatus(value) {
      this.$emit('set-purchase-status', value);
    }
  }
};
</script>

<style scoped lang="scss">
.v-card__chart {
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.mobile {
    padding: 16px;
  }

  ::v-deep .v-card__title,
  ::v-deep .v-card__text {
    padding: 0;
  }

  ::v-deep .v-card__text {
    flex: 1 1 auto;
    height: 100%;
    align-self: center;
  }

  &.v-card__chart--has-content {
    ::v-deep .v-card__text {
      min-height: 350px;
    }

    &:not(.v-card__chart--full-width) ::v-deep .v-card__text {
      max-width: 500px;
    }

    &.v-card__chart--full-width ::v-deep .v-card__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

::v-deep .apexcharts-canvas .apexcharts-text {
  font-family: 'Open-sans', sans-serif;
}

::v-deep .apexcharts-legend {
  padding-left: 0;
  left: 0;
}

::v-deep .apexcharts-legend-marker {
  min-width: 10px;
  width: 10px;
  max-width: 10px;
  height: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

::v-deep .apexcharts-legend-series {
  display: flex;
  align-items: baseline;
  padding: 5px 0;
}

::v-deep .apexcharts-tooltip {
  border-radius: 2px;
  border: none;
  background: white;
  font-family: 'Open-sans', sans-serif;
  font-size: 14px;

  .tooltip__box {
    color: white;
    text-align: center;
    padding: 0.7rem 1rem;
    filter: brightness(95%);
  }
}

.theme--light.v-btn.v-btn--select:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  min-width: 100px;
  min-height: unset;
  height: auto;
  padding: 0.5rem;
  background-color: #c6defb;
  color: var(--c-text);
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;

  &.active {
    background-color: #1166ec;
    color: white;
  }
}

.title-menu__wrapper {
  max-width: 150px;
  margin-top: -2px;

  ::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: unset;
  }
}

::v-deep .axis-chart {
  .apexcharts-legend-marker {
    max-width: unset;
    max-height: unset;
    margin-right: 10px;
  }

  .apexcharts-tooltip-series-group {
    align-items: start;
    margin-bottom: 6px;
  }

  .apexcharts-tooltip-marker {
    margin-top: 3px;
  }

  .apexcharts-tooltip-y-group {
    padding: 0 6px;
  }

  .apexcharts-tooltip-text-value {
    margin-left: 0;
  }
}
</style>
