import formatPercentage from '@/helper/filter/formatPercentage';
import formatCurrency from '@/helper/filter/formatCurrency';
import CHART_COLORS from '@/modules/Dashboard/statics/chartColors';

export default {
  data: () => ({
    chartData: null,
    loading: false,
    error: false,
    filterPurchaseStatus: 'all',
    selectedYear: ''
  }),

  computed: {
    chartColors() {
      return CHART_COLORS;
    },
    monthsList() {
      return Object.keys(this.$t('dashboard.months'));
    },
    yearItems() {
      if (!this.chartData) {
        return [];
      }

      return Object.keys(this.chartData).map((year) => ({
        label: year,
        value: Number(year)
      }));
    },
    hasNoData() {
      if (this.loading || this.error) {
        return;
      }

      if (Array.isArray(this.chartData)) {
        return this.chartData.length === 0;
      }

      return !this.chartData || Object.keys(this.chartData).length === 0;
    },
    chartDefaultOptions() {
      return {
        colors: this.chartColors,
        dictionary: {
          invoice: this.$t('invoice'),
          invoices: this.$t('invoices'),
          voucher: this.$t('voucher'),
          vouchers: this.$t('voucherPl')
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return formatPercentage(val, 1);
          },
          style: {
            fontSize: '10px',
            fontFamily: 'Open-Sans, sans-serif',
            fontWeight: 'bold',
            color: 'white'
          }
        },
        legend: {
          position: 'left',
          fontSize: '12px',
          fontFamily: 'Open-Sans, sans-serif',
          fontWeight: 400,
          offsetX: -16,
          offsetY: 0,
          width: 150
        },
        responsive: [
          {
            breakpoint: this.$vuetify.breakpoint.thresholds.md,
            options: {
              legend: {
                position: 'bottom',
                offsetX: 0,
                width: '100%'
              },
              chart: {
                height: 350
              }
            }
          }
        ]
      };
    },
    axisChartDefaultOptions() {
      return {
        dictionary: {
          invoice: this.$t('invoice'),
          invoices: this.$t('invoices'),
          day: this.$t('day'),
          days: this.$t('days'),
          voucher: this.$t('voucher'),
          vouchers: this.$t('voucherPl')
        },
        monthsTranslations: this.monthsList.map((month) =>
          this.$t(`dashboard.monthsFull.${month}`)
        ),
        xaxis: {
          categories: this.monthsList,
          labels: {
            style: {
              fontSize: '10px',
              fontFamily: 'Open-Sans, sans-serif',
              fontWeight: 400
            },
            formatter: (value) => {
              return this.$te(`dashboard.months.${value}`)
                ? this.$t(`dashboard.months.${value}`)
                : value;
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10px',
              fontFamily: 'Open-Sans, sans-serif',
              fontWeight: 400
            }
          },
          title: {
            text: this.$t('days'),
            style: {
              fontSize: '12px',
              fontFamily: 'Open-Sans, sans-serif',
              fontWeight: 600
            }
          }
        },
        dataLabels: {
          formatter: function (value) {
            return value > 0 ? value : '';
          }
        },
        tooltip: {
          style: {
            fontSize: '12px',
            fontFamily: 'Open-Sans, sans-serif'
          },
          custom: function ({ w, series, dataPointIndex, seriesIndex }) {
            let quantity = 0;

            if (Array.isArray(w.config.quantity[0]) === true) {
              quantity = w.config.quantity[seriesIndex][dataPointIndex];
            } else {
              quantity = w.config.quantity[dataPointIndex];
            }
            return `
                <div class="tooltip__box text--text" >
                    <div>
                        ${w.config.monthsTranslations[dataPointIndex]}:
                        <b>${series[seriesIndex][dataPointIndex]}
                        ${
                          series[seriesIndex][dataPointIndex] === 1
                            ? w.config.dictionary.day
                            : w.config.dictionary.days
                        }
                        </b>
                        <div class="caption">
                        (${quantity}
                          ${
                            quantity === 1
                              ? w.config.dictionary.invoice
                              : w.config.dictionary.invoices
                          })
                        </div>
                    </div>
                </div>`;
          }
        }
      };
    },
    donutChartDefaultOptions() {
      return {
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: '18px',
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: 600,
                  offsetY: 4,
                  formatter: function (val) {
                    return `${formatCurrency(val)}`;
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: this.$t('total'),
                  fontSize: '20px',
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: 600,
                  color: this.$vuetify.theme.themes.light.primary,
                  formatter: function (w) {
                    return formatCurrency(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0)
                    );
                  }
                }
              }
            },
            dataLabels: {
              minAngleToShowLabel: 5
            }
          }
        }
      };
    },
    lineChartDefaultOptions() {
      return {
        legend: {
          position: 'bottom',
          fontSize: '12px',
          horizontalAlign: 'center',
          fontWeight: 400,
          markers: {
            width: 20,
            height: 12,
            radius: 2,
            offsetX: 0,
            offsetY: 0
          },
          offsetY: 12,
          itemMargin: {
            horizontal: 12
          }
        }
      };
    }
  },

  watch: {
    yearItems() {
      if (!this.chartData) {
        return;
      }
      this.selectedYear = Math.max(...this.yearItems.map((item) => item.value));
    }
  }
};
