<template>
  <chart-card
    full-width
    title="dashboard.lastCreditorVouchers"
    :error="error"
    :no-data="!loadingList && creditorVouchers.length === 0"
  >
    <template v-if="!loadingList">
      <v-divider />
      <v-list class="flex-grow-1 d-flex flex-column pa-0">
        <template v-for="(voucher, index) in creditorVouchers">
          <v-list-item :key="index" class="flex-auto">
            <v-list-item-content class="py-1">
              <v-list-item-title class="semi-bold subtitle-1">
                <v-row no-gutters class="justify-space-between flex-fill">
                  <v-col cols="auto" class="d-flex align-center mr-4">
                    {{ voucher.type | formatCreditorVoucherType }}
                    <app-icon-btn
                      v-if="voucher.source"
                      :loading="loadingFileType === index"
                      class="ml-2"
                      icon="icon-file-pdf"
                      size="19"
                      x-small
                      @click="openFile(voucher.source, index, $t('pdf'))"
                    />
                  </v-col>
                  <v-col cols="auto">
                    {{ voucher.amount | formatCurrency }}
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle class="caption text--text">
                {{ voucher.sapNumber }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="caption grey--text">
                {{ voucher.voucherDate | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'divider_' + index" />
        </template>

        <v-spacer />
        <v-list-item class="justify-end flex-auto">
          <app-link-btn
            class="caption"
            title="dashboard.toCreditorVouchers"
            variant="muted"
            :to="{ name: routeName.MASTER_DATA, query: { tab: 2 } }"
          />
        </v-list-item>
      </v-list>
    </template>
  </chart-card>
</template>

<script>
import { NAMESPACE } from '@/modules/Dashboard/store';
import { mapActions } from 'vuex';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';

export default {
  name: 'CreditorVoucherChart',

  mixins: [OpenFileMixin],

  components: {
    ChartCard
  },

  data: () => ({
    creditorVouchers: [],
    loadingList: false,
    error: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['fetchCreditorVoucherStatistic'])
  },

  async created() {
    this.loadingList = true;
    const { data, error } = await this.fetchCreditorVoucherStatistic();

    this.loadingList = false;
    if (error) {
      this.error = true;
      return;
    }

    this.creditorVouchers = data;
  }
};
</script>

<style scoped>
.v-card__chart >>> .v-card__text {
  max-width: unset;
}
</style>
