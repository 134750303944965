<template>
  <chart-card
    title="dashboard.openInvoices"
    filter-purchase-status
    :no-data="hasNoData"
    @set-purchase-status="filterPurchaseStatus = $event"
  >
    <vue-apex-charts type="donut" :options="chart.options" :series="chart.series" />
  </chart-card>
</template>

<script>
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';
import ChartMixin from '@/mixins/ChartMixin';
import DunningLevel from '@/modules/Dashboard/statics/dunningLevel';
import VueApexCharts from 'vue-apexcharts';
import formatCurrency from '@/helper/filter/formatCurrency';

const ORDERED_TYPES = [
  DunningLevel.NOT_DUNNED,
  DunningLevel.HALT,
  DunningLevel.PAYMENT_REMINDER,
  DunningLevel.DUNNING,
  DunningLevel.ENCASHMENT
];

export default {
  name: 'OpenInvoicesChart',

  mixins: [ChartMixin],

  components: {
    ChartCard,
    VueApexCharts
  },

  props: {
    invoices: {
      type: Object,
      required: true
    }
  },

  computed: {
    chart() {
      return {
        series: this.series,
        options: {
          ...this.chartDefaultOptions,
          ...this.donutChartDefaultOptions,
          quantity: ORDERED_TYPES.map(
            (key) => this.invoices[key][this.filterPurchaseStatus].quantity
          ),
          labels: ORDERED_TYPES.map((type) => this.$t(`dashboard.openInvoiceChartLabels.${type}`)),
          colors: this.chartColors,
          tooltip: {
            custom: function ({ w, series, seriesIndex }) {
              return `
                <div class="tooltip__box" style="background-color: ${w.config.colors[seriesIndex]}">
                        ${w.config.labels[seriesIndex]}:
                        <b>${formatCurrency(series[seriesIndex])}</b>
                        <div class="caption">(${w.config.quantity[seriesIndex]}
                          ${
                            w.config.quantity[seriesIndex] === 1
                              ? w.config.dictionary.invoice
                              : w.config.dictionary.invoices
                          })
                    </div>
                </div>`;
            }
          }
        }
      };
    },
    series() {
      return ORDERED_TYPES.map((key) => this.invoices[key][this.filterPurchaseStatus].amount);
    },
    hasNoData() {
      return (
        Object.entries(this.invoices).reduce((sum, entry) => sum + entry[1].all.amount, 0) === 0
      );
    }
  }
};
</script>
