<template>
  <v-container fluid :class="{ 'px-3': isMobile, 'pa-0': !isMobile }" :fill-height="!statistic">
    <template v-if="statistic">
      <h4>{{ $t('dashboard.factsOverview') }}</h4>
      <v-row align="stretch" justify="start" class="widget__row">
        <v-col v-if="permissions.dashboard.dunningBlock">
          <widget-card
            :text="$tc('dashboard.vouchersInDunningBlockCounted', statistic.vouchersInDunningBlock)"
            icon="icon-block"
            :icon-color="statistic.vouchersInDunningBlock === 0 ? 'text' : 'error'"
            :title="statistic.vouchersInDunningBlock"
          />
        </v-col>
        <v-col v-if="showDelayCountWidget">
          <widget-card
            :text="$tc('dashboard.vouchersInDelayBlockCounted', statistic.delay)"
            icon="icon-delay"
            :icon-color="statistic.delay === 0 ? 'text' : 'error'"
            :title="statistic.delay"
          />
        </v-col>
        <v-col v-if="showDisputeCountWidget">
          <widget-card
            :text="$tc('dashboard.vouchersInDisputeBlockCounted', statistic.dispute)"
            icon="icon-dispute"
            :icon-color="statistic.dispute === 0 ? 'text' : 'error'"
            :title="statistic.dispute"
          />
        </v-col>
        <v-col v-if="permissions.dashboard.cashback">
          <widget-card icon="icon-euro" icon-color="success">
            <template #title>
              {{ currentCashbackLevel.amount | formatCurrency }}
            </template>
            <template #prepend>
              <widget-bar
                :value="currentCashbackRate"
                bottom-color="var(--c-success)"
                top-color="var(--c-border)"
              />
            </template>
            <template #text>
              <p>{{ $t('dashboard.cashbackCredit') }}</p>
              <span
                v-if="!currentCashbackLevel.isMaxLevel"
                class="d-block overline"
                v-html="
                  $t('dashboard.amountToNextCashbackLevel', {
                    amount: amountToNextCashbackLevel
                  })
                "
              ></span>
            </template>
          </widget-card>
        </v-col>

        <v-col v-if="showServiceFeeWidget">
          <widget-card>
            <template #title>
              {{ statistic.serviceFeeAmount | formatCurrency }}
            </template>

            <template #subTitle>
              {{ $t('dashboard.of') }} {{ statistic.currentServiceFeeAnnual | formatCurrency }}
            </template>

            <template #prepend>
              <widget-bar
                :value="statistic.periodServiceFeeAnnualPercent"
                :bottom-color="
                  statistic.periodServiceFeeAnnualPercent < 100
                    ? 'var(--c-error)'
                    : 'var(--c-success)'
                "
                top-color="var(--c-border)"
              />
            </template>
            <template #text>
              <p
                v-if="statistic.periodServiceFeeAnnualPercent < 100"
                v-html="$t('dashboard.notReachedServiceFeeAnnual')"
              ></p>
              <p v-else v-html="$t('dashboard.reachedServiceFeeAnnual')"></p>
            </template>
          </widget-card>
        </v-col>

        <v-col v-if="permissions.dashboard.availableCreditorLimit">
          <widget-card text="debtors.availableCreditorLimit" icon="icon-euro">
            <template #title>
              {{ statistic.creditorLimit.availableLimit | formatCurrency }}
            </template>
            <template #prepend>
              <widget-bar :value="exhaustedLimitRate" />
            </template>
          </widget-card>
        </v-col>

        <v-col v-if="permissions.dashboard.debtorLimitRate">
          <widget-card text="dashboard.debtorLimitRate" icon="icon-money">
            <template #title>
              {{ formatPercentageToOneDecimal(statistic.debtorLimitRate) }}
            </template>
          </widget-card>
        </v-col>

        <v-col v-if="permissions.dashboard.overdueRate">
          <widget-card text="dashboard.overdueRate" icon="icon-calendar">
            <template #title>
              {{ formatPercentageToOneDecimal(statistic.overdueRate) }}
            </template>
          </widget-card>
        </v-col>

        <v-col
          :class="{
            'widget__col--extended': !!statistic.oldestWorkQueue
          }"
        >
          <widget-card v-if="statistic.oldestWorkQueue" error>
            <v-list-item class="widget-card__content" @click="pushToWorkQueue">
              <v-list-item-content class="pa-0">
                <div class="overline text-uppercase muted grey--text">
                  {{ statistic.oldestWorkQueue.debtor }}
                </div>
                <v-list-item-title class="semi-bold text-h3 error--text">
                  {{ statistic.oldestWorkQueue.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption text--text">
                  {{ getCustomNumbers(statistic.oldestWorkQueue.vouchers) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-2 caption d-flex justify-space-between muted">
                  <div class="flex-fill">
                    {{ statistic.oldestWorkQueue.updated | formatDate }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </widget-card>
          <widget-card v-else title="0" text="dashboard.openWorkQueue" icon="icon-work-queue" />
        </v-col>
      </v-row>

      <h4>{{ $t('dashboard.statisticsInDetail') }}</h4>
      <v-row justify="center" justify-lg="start" align="stretch">
        <v-col cols="12" md="6">
          <creditor-voucher-chart />
        </v-col>
        <v-col cols="12" md="6">
          <dunning-type-invoices-chart v-if="showOpenDunningTypeInvoices" />
          <open-invoices-chart v-else :invoices="statistic.invoices" />
        </v-col>
      </v-row>

      <v-row justify="center" justify-lg="start" align="stretch">
        <v-col v-if="permissions.dashboard.turnoverVolumeChart" cols="12" md="6">
          <turnover-volume-chart />
        </v-col>
        <v-col v-if="permissions.dashboard.turnoverProgressionChart" cols="12" md="6">
          <turnover-progression-chart />
        </v-col>
      </v-row>

      <v-row justify="center" justify-lg="start" align="stretch">
        <v-col v-if="permissions.dashboard.daysSalesOutstandingChart" cols="12" md="6">
          <days-sales-outstanding-chart />
        </v-col>
        <v-col v-if="permissions.dashboard.daysOverdueChart" cols="12" md="6">
          <days-overdue-chart />
        </v-col>
      </v-row>

      <v-row justify="center" justify-lg="start" align="stretch">
        <v-col v-if="permissions.dashboard.balancingChart" cols="12" md="6">
          <balancing-chart />
        </v-col>
      </v-row>
    </template>

    <app-loading-sheet v-else-if="loading" />

    <v-sheet
      v-else-if="error"
      class="d-flex flex-column align-center justify-center pa-4"
      elevation="12"
      style="margin: 0 auto"
    >
      <app-error-alert />
    </v-sheet>
  </v-container>
</template>

<script>
import BalancingChart from '@/modules/Dashboard/components/Charts/BalancingChart';
import CASHBACK_LEVELS from '@/modules/Dashboard/statics/cashbackLevels';
import DunningType from '@/statics/dunningType';
import CreditorVoucherChart from '@/modules/Dashboard/components/Charts/CreditorVoucherChart';
import DaysOverdueChart from '@/modules/Dashboard/components/Charts/DaysOverdueChart';
import DaysSalesOutstandingChart from '@/modules/Dashboard/components/Charts/DaysSalesOutstandingChart';
import OpenInvoicesChart from '@/modules/Dashboard/components/Charts/OpenInvoicesChart';
import DunningTypeInvoicesChart from '@/modules/Dashboard/components/Charts/DunningTypeInvoicesChart';
import TurnoverProgressionChart from '@/modules/Dashboard/components/Charts/TurnoverProgressionChart';
import TurnoverVolumeChart from '@/modules/Dashboard/components/Charts/TurnoverVolumeChart';
import WidgetBar from '@/modules/Dashboard/components/WidgetBar';
import WidgetCard from '@/modules/Dashboard/components/WidgetCard';
import formatCurrency from '@/helper/filter/formatCurrency';
import formatPercentage from '@/helper/filter/formatPercentage';
import getCustomNumbersFromVoucherList from '@/helper/getCustomNumbersFromVoucherList';
import { NAMESPACE } from '@/modules/Dashboard/store';
import { mapActions } from 'vuex';

export default {
  name: 'Dashboard',

  components: {
    WidgetBar,
    WidgetCard,
    CreditorVoucherChart,
    OpenInvoicesChart,
    DunningTypeInvoicesChart,
    TurnoverVolumeChart,
    TurnoverProgressionChart,
    BalancingChart,
    DaysOverdueChart,
    DaysSalesOutstandingChart
  },

  data: () => ({
    statistic: null,
    error: false,
    loading: false
  }),

  computed: {
    availableLimitRate() {
      if (!this.statistic.creditorLimit.approvedLimit) {
        return;
      }

      return (
        (this.statistic.creditorLimit.availableLimit / this.statistic.creditorLimit.approvedLimit) *
        100
      );
    },
    exhaustedLimitRate() {
      // percentage of approvedCreditorLimit which is spent by purchasing invoices
      return 100 - this.availableLimitRate;
    },

    currentCashbackLevel() {
      return (
        CASHBACK_LEVELS.find(
          (level) =>
            level.min <= this.statistic.annualTurnover && this.statistic.annualTurnover < level.max
        ) ?? CASHBACK_LEVELS.slice(-1)[0]
      );
    },
    currentCashbackRate() {
      // percentage of progress until next cashback level
      if (this.currentCashbackLevel.isMaxLevel) {
        return 100;
      }
      return (
        ((this.statistic.annualTurnover - this.currentCashbackLevel.min) * 100) /
        (this.currentCashbackLevel.max - this.currentCashbackLevel.min)
      );
    },
    amountToNextCashbackLevel() {
      return formatCurrency(this.currentCashbackLevel.max - this.statistic.annualTurnover + 1);
    },

    showServiceFeeWidget() {
      // temporarily disabled; to enable: return this.$auth.user().isServiceFeePeriodActive
      return false;
    },
    showDelayCountWidget() {
      return this.permissions.dashboard.delay;
    },
    showDisputeCountWidget() {
      return this.permissions.dashboard.dispute;
    },
    showOpenDunningTypeInvoices() {
      return this.permissions.dashboard.showOpenDunningTypeInvoices;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchCreditorStatistic',
      'fetchServiceFeePeriodStatistic',
      'fetchDunningTypeCountStatistic'
    ]),

    pushToWorkQueue() {
      this.$router.push({
        name: this.routeName.WORK_QUEUE,
        params: { id: this.statistic.oldestWorkQueue.id }
      });
    },

    getCustomNumbers(vouchers) {
      return getCustomNumbersFromVoucherList(vouchers);
    },

    formatPercentageToOneDecimal(number) {
      return formatPercentage(number, 1);
    }
  },

  created: async function () {
    this.loading = true;

    const serviceFeeFetch = this.showServiceFeeWidget
      ? this.fetchServiceFeePeriodStatistic
      : () =>
          Promise.resolve({
            data: { currentServiceFeeAnnual: '0', serviceFeeAmount: '0' }
          });

    const disputeDataFetch = this.showDisputeCountWidget
      ? () => this.fetchDunningTypeCountStatistic(DunningType.DISPUTE.toLowerCase())
      : () => {
          return Promise.resolve({
            data: { count: '0' }
          });
        };

    const delayDataFetch = this.showDelayCountWidget
      ? () => this.fetchDunningTypeCountStatistic(DunningType.DELAY.toLowerCase())
      : // eslint-disable-next-line sonarjs/no-identical-functions
        () => {
          return Promise.resolve({
            data: { count: '0' }
          });
        };

    const [
      { data, error },
      { data: feeData, error: feeError },
      { data: disputeData, error: disputeError },
      { data: delayData, error: delayError }
    ] = await Promise.all([
      this.fetchCreditorStatistic(),
      serviceFeeFetch(),
      disputeDataFetch(),
      delayDataFetch()
    ]);
    this.loading = false;

    if (error || feeError || disputeError || delayError) {
      this.error = true;
      return;
    }

    const currentServiceFeeAnnual = parseInt(feeData.currentServiceFeeAnnual);
    const serviceFeeAmount = parseInt(feeData.serviceFeeAmount);
    const periodServiceFeeAnnualPercent = serviceFeeAmount / (currentServiceFeeAnnual / 100);

    this.statistic = {
      ...data,
      currentServiceFeeAnnual: currentServiceFeeAnnual,
      serviceFeeAmount: serviceFeeAmount,
      periodServiceFeeAnnualPercent: Math.max(
        serviceFeeAmount > currentServiceFeeAnnual ? 100 : periodServiceFeeAnnualPercent,
        10 // Use min 10% to visualize progress
      ),
      dispute: parseInt(disputeData.count),
      delay: parseInt(delayData.count)
    };
  }
};
</script>

<style lang="scss" scoped>
h4 {
  color: var(--c-background-text);
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.v-image,
.v-card {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22), 0 14px 28px 0 rgba(0, 0, 0, 0.25);
}

.widget__row {
  display: flex;

  > .col {
    max-width: 50%;
  }

  .widget__col--extended {
    flex-grow: 2;
  }
}

.v-application.mobile {
  h4 {
    margin-top: 1rem;
  }

  .widget__row > .col {
    max-width: revert;
  }
}
</style>
