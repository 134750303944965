<template>
  <chart-card
    full-width
    title="dashboard.turnoverProgressionChart"
    :error="error"
    :no-data="hasNoData"
  >
    <vue-apex-charts
      v-if="chartData && !loading"
      :options="chart.options"
      :series="chart.series"
      height="350"
      class="axis-chart"
    />
  </chart-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { NAMESPACE } from '@/modules/Dashboard/store';
import { mapActions } from 'vuex';
import formatCurrency from '@/helper/filter/formatCurrency';
import ChartMixin from '@/mixins/ChartMixin';
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';

export default {
  name: 'TurnoverProgressionChart',

  mixins: [ChartMixin],

  components: {
    ChartCard,
    VueApexCharts
  },

  data: () => ({
    chartData: []
  }),

  computed: {
    chart() {
      return {
        series: this.chartData,
        options: {
          ...this.axisChartDefaultOptions,
          ...this.lineChartDefaultOptions,
          chart: {
            width: '100%',
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          stroke: {
            curve: 'smooth',
            width: 4
          },
          colors: this.chartColors,
          yaxis: {
            labels: {
              style: {
                fontSize: '10px',
                fontFamily: 'Open-Sans, sans-serif',
                fontWeight: 400
              },
              formatter: (value) => {
                return formatCurrency(value, 0);
              }
            }
          },
          tooltip: {
            theme: false,
            style: {
              fontSize: '14px',
              fontFamily: 'Open-Sans, sans-serif'
            },
            x: {
              formatter: (value) => {
                return this.$te(`dashboard.monthsFull.${this.monthsList[value - 1]}`)
                  ? this.$t(`dashboard.monthsFull.${this.monthsList[value - 1]}`)
                  : value;
              }
            },
            y: {
              title: {
                formatter: () => ''
              },
              formatter: (seriesName, { w, seriesIndex, dataPointIndex }) => {
                return `<div class="subtitle-1 semi-bold">
                    ${w.config.series[seriesIndex].name}: ${formatCurrency(seriesName)}
                  </div>
                  <div class="caption">(${w.config.series[seriesIndex].quantity[dataPointIndex]}
                    ${
                      w.config.series[seriesIndex].quantity[dataPointIndex] === 1
                        ? w.config.dictionary.invoice
                        : w.config.dictionary.invoices
                    })
                  </div>`;
              }
            }
          }
        }
      };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchTurnoverProgressionStatistic'])
  },

  async created() {
    this.loading = true;
    const { data, error } = await this.fetchTurnoverProgressionStatistic();
    this.loading = false;

    if (error) {
      this.error = true;
      return;
    }

    this.chartData = Object.keys(data).reduce((seriesArr, year) => {
      seriesArr.push({
        name: year,
        data: data[year].map((month) => Number(month.amount) ?? 0),
        quantity: data[year].map((month) => Number(month.quantity) ?? 0)
      });
      return seriesArr;
    }, []);
  }
};
</script>
