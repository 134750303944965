<template>
  <chart-card
    full-width
    title="dashboard.daysSalesOutstandingChart"
    :error="error"
    :no-data="hasNoData"
  >
    <template #title-menu>
      <app-select
        v-if="!loading"
        id="days-sales-outstanding-chart__year"
        v-model="selectedYear"
        :items="yearItems"
        label="year"
        required
      />
    </template>

    <vue-apex-charts
      v-if="chartData && !loading"
      :options="chart.options"
      :series="chart.series"
      type="line"
      height="350"
      class="axis-chart"
    />
  </chart-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { BLUE, GREEN, ORANGE } from '@/modules/Dashboard/statics/chartColors';
import { NAMESPACE } from '@/modules/Dashboard/store';
import { mapActions } from 'vuex';
import ChartMixin from '@/mixins/ChartMixin';
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';

export default {
  name: 'DaysSalesOutstandingChart',

  mixins: [ChartMixin],

  components: {
    ChartCard,
    VueApexCharts
  },

  computed: {
    chart() {
      return {
        series: [
          {
            name: this.$t('vouchers.statusToShowOptions.open'),
            data: this.dataOfSelectedYear.open.map((data) => data.days),
            type: 'column'
          },
          {
            name: this.$t('vouchers.balancing.balanced'),
            data: this.dataOfSelectedYear.balanced.map((data) => data.days),
            type: 'column'
          },
          {
            name: this.$t('dashboard.paymentTarget'),
            data: this.dataOfSelectedYear['payment_target'].map((days) => Math.round(days)),
            type: 'line'
          }
        ],
        options: {
          ...this.axisChartDefaultOptions,
          ...this.lineChartDefaultOptions,
          quantity: [
            this.dataOfSelectedYear.open.map((data) => data.quantity),
            this.dataOfSelectedYear.balanced.map((data) => data.quantity)
          ],
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          stroke: {
            curve: 'smooth',
            width: [0, 0, 2]
          },
          colors: [ORANGE, GREEN, BLUE],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '95%'
            }
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
            formatter: function (value) {
              return value > 0 ? value : '';
            },
            style: {
              colors: ['white']
            },
            background: {
              enabled: false
            },
            dropShadow: {
              enabled: false
            }
          },
          tooltip: {
            shared: true,
            theme: false,
            style: {
              fontSize: '14px',
              fontFamily: 'Open-Sans, sans-serif'
            },
            x: {
              formatter: (value) => {
                return this.$te(`dashboard.monthsFull.${this.monthsList[value - 1]}`)
                  ? this.$t(`dashboard.monthsFull.${this.monthsList[value - 1]}`)
                  : value;
              }
            },
            y: {
              title: {
                formatter: () => ''
              },
              formatter: (seriesName, { w, seriesIndex, dataPointIndex }) => {
                let template = `
                  <div class="subtitle-1 semi-bold">
                    ${w.config.series[seriesIndex].name}: ${seriesName} ${
                  seriesName === 1 ? w.config.dictionary.day : w.config.dictionary.days
                }
                  </div>`;

                if (w.config.quantity?.[seriesIndex]) {
                  template += `
                    <div class="caption">
                    (${w.config.quantity[seriesIndex][dataPointIndex]} ${
                    w.config.quantity[seriesIndex][dataPointIndex] === 1
                      ? w.config.dictionary.invoice
                      : w.config.dictionary.invoices
                  })
                    </div>`;
                }

                return template;
              }
            }
          }
        }
      };
    },
    dataOfSelectedYear() {
      return this.chartData[this.selectedYear] ?? { open: [], balanced: [], payment_target: [] };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDaysSalesOutstandingStatistic'])
  },

  async created() {
    this.loading = true;
    const { data, error } = await this.fetchDaysSalesOutstandingStatistic();
    this.loading = false;

    if (error) {
      this.error = true;
      return;
    }

    this.chartData = data;
  }
};
</script>
