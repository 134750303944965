export const CASHBACK_LEVELS = [
  { min: 0, max: 14999999, amount: 0 },
  { min: 15000000, max: 24999999, amount: 25000 },
  { min: 25000000, max: 34999999, amount: 50000 },
  { min: 35000000, max: 49999999, amount: 100000 },
  { min: 50000000, max: 74999999, amount: 150000 },
  { min: 75000000, max: 99999999, amount: 250000 },
  { min: 75000000, max: 99999999, amount: 250000 },
  { min: 100000000, max: 149999999, amount: 500000 },
  { min: 150000000, max: Number.POSITIVE_INFINITY, amount: 1000000, isMaxLevel: true }
];

export default CASHBACK_LEVELS;
