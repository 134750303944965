<template>
  <chart-card
    title="dashboard.turnoverVolumeChart"
    filter-purchase-status
    :no-data="hasNoData"
    @set-purchase-status="filterPurchaseStatus = $event"
  >
    <template #title-menu>
      <app-select
        v-if="!loading"
        id="turnover-chart__year"
        v-model="selectedYear"
        :items="yearItems"
        label="year"
        required
      />
    </template>

    <vue-apex-charts
      v-if="chartData && !loading"
      :options="chart.options"
      :series="chart.series"
      type="donut"
    />
  </chart-card>
</template>

<script>
const ORDERED_TYPES = ['open', 'balanced'];

import VueApexCharts from 'vue-apexcharts';
import { GREEN, ORANGE } from '@/modules/Dashboard/statics/chartColors';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Dashboard/store';
import ChartMixin from '@/mixins/ChartMixin';
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';
import formatCurrency from '@/helper/filter/formatCurrency';

export default {
  name: 'TurnoverVolumeChart',

  mixins: [ChartMixin],

  components: {
    VueApexCharts,
    ChartCard
  },

  computed: {
    chart() {
      return {
        series: this.orderedValues.amount,
        options: {
          ...this.chartDefaultOptions,
          ...this.donutChartDefaultOptions,
          quantity: this.orderedValues.quantity,
          labels: ORDERED_TYPES.map((type) =>
            this.$t(`dashboard.turnoverVolumeChartLabels.${type}`)
          ),
          colors: [ORANGE, GREEN],
          dictionary: { invoice: this.$t('invoice'), invoices: this.$t('invoices') },
          tooltip: {
            custom: function ({ w, series, seriesIndex }) {
              return `
                <div class="tooltip__box" style="background-color: ${w.config.colors[seriesIndex]}">
                        ${w.config.labels[seriesIndex]}:
                        <b>${formatCurrency(series[seriesIndex])}</b>
                        <div class="caption">(${w.config.quantity[seriesIndex]}
                          ${
                            w.config.quantity[seriesIndex] === 1
                              ? w.config.dictionary.invoice
                              : w.config.dictionary.invoices
                          })
                    </div>
                </div>`;
            }
          }
        }
      };
    },
    dataOfSelectedYear() {
      return this.chartData[this.selectedYear];
    },
    orderedValues() {
      if (!this.dataOfSelectedYear) {
        return {};
      }

      return ORDERED_TYPES.reduce(
        (object, key) => {
          object['amount'].push(this.dataOfSelectedYear[key][this.filterPurchaseStatus].amount);
          object['quantity'].push(this.dataOfSelectedYear[key][this.filterPurchaseStatus].quantity);

          return object;
        },
        { amount: [], quantity: [] }
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchTurnoverVolumeStatistic'])
  },

  async created() {
    this.loading = true;
    const { data, error } = await this.fetchTurnoverVolumeStatistic();
    this.loading = false;

    if (error) {
      this.error = true;
      return;
    }

    this.chartData = data;
  }
};
</script>
