<template>
  <div
    class="bar__wrapper"
    :style="`background-image: linear-gradient( to top, ${bottomColor} calc(${value}% - 1px), white, ${topColor} calc(${value}% + 1px))`"
  ></div>
</template>

<script>
export default {
  name: 'WidgetBar',

  props: {
    value: {
      type: Number,
      default: 0
    },
    topColor: {
      type: String,
      default: 'var(--c-success)'
    },
    bottomColor: {
      type: String,
      default: 'var(--c-error)'
    }
  }
};
</script>

<style scoped>
.bar__wrapper {
  height: 100%;
  width: 12px;
  border-radius: 6px;
}
</style>
