<template>
  <div class="white fill-height">
    <v-card
      v-bind="$attrs"
      elevation="12"
      :class="{
        'd-flex fill-height justify-start ': true,
        'widget-card--error': error
      }"
      v-on="$listeners"
    >
      <slot>
        <v-row class="widget-card__content" no-gutters>
          <v-col cols="auto" class="widget-card__prepend">
            <slot name="prepend"></slot>
          </v-col>

          <v-col class="d-flex flex-column grow">
            <v-card-title :class="titleClasses">
              <slot name="title">
                <div class="flex-auto">
                  {{ $te(title) ? $t(title) : title }}
                </div>
              </slot>

              <span v-if="$slots.subTitle" class="sub-title">
                <slot name="subTitle"></slot>
              </span>

              <slot name="icon">
                <component :is="icon" :class="`widget-card__icon ${iconColor}--text`" />
              </slot>
            </v-card-title>
            <v-card-text class="pa-0 body-1">
              <slot name="text">
                {{ $te(text) ? $t(text) : text }}
              </slot>
            </v-card-text>
          </v-col>
        </v-row>
      </slot>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'WidgetCard',

  props: {
    error: {
      type: Boolean,
      default: false
    },
    title: {
      type: [Number, String],
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    titleClasses() {
      const baseClasses = 'widget-card__title text-h3 ';
      const flexBoxClasses = 'd-flex justify-space-between align-start flex-nowrap';
      const marginClasses = this.$slots.subTitle ? 'mb-2' : 'mb-4';

      return this.icon
        ? `${baseClasses} ${flexBoxClasses} ${marginClasses}`
        : `${baseClasses} ${marginClasses}`;
    }
  }
};
</script>

<style scoped lang="scss">
.widget-card__title {
  padding: 0;
  margin: 0;
  word-break: normal;
  font-weight: var(--font-weight-semi-bold);
}

.v-card ::v-deep .widget-card__content {
  max-width: 100%;
  padding: 24px 24px 24px 32px;
  flex-wrap: nowrap;
}
.v-card .widget-card__prepend {
  margin-left: -1rem;
  margin-right: 1rem;
}

.v-card .widget-card__icon {
  min-width: 35px;
  min-height: 35px;
  margin-left: 24px;
}

.v-card.widget-card--error {
  border-radius: 1px;
  background-color: rgba(255, 54, 54, 0.1);
  border-left: 7px solid var(--c-error);
  border-top: unset;
  border-bottom: unset;
}

.sub-title {
  display: block;
  width: 100% !important;
  font-size: 13px;
  color: var(--c-grey);
  line-height: 0.8em;
}
</style>
