<template>
  <chart-card title="dashboard.balancingChart" :error="error" :no-data="hasNoData">
    <template #title-menu>
      <app-select
        v-if="!loading"
        id="balancing-chart__year"
        v-model="selectedYear"
        :items="yearItems"
        label="year"
        required
      />
    </template>

    <vue-apex-charts
      v-if="chartData && !loading"
      :options="chart.options"
      :series="chart.series"
      type="donut"
    />
  </chart-card>
</template>

<script>
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';
import ChartMixin from '@/mixins/ChartMixin';
import VueApexCharts from 'vue-apexcharts';
import formatCurrency from '@/helper/filter/formatCurrency';
import formatVoucherType from '@/helper/filter/formatVoucherType';
import { NAMESPACE } from '@/modules/Dashboard/store';
import { VOUCHER_TYPES } from '@/statics/voucherType';
import { mapActions } from 'vuex';

export default {
  name: 'BalancingChart',

  mixins: [ChartMixin],

  components: {
    ChartCard,
    VueApexCharts
  },

  computed: {
    chart() {
      return {
        series: this.orderedValues.map((item) => item.amount),
        options: {
          ...this.chartDefaultOptions,
          ...this.donutChartDefaultOptions,
          quantity: this.orderedValues.map((item) => item.quantity),
          labels: this.orderedTypes,
          tooltip: {
            custom: function ({ w, series, seriesIndex }) {
              return `
                <div class="tooltip__box" style="background-color: ${w.config.colors[seriesIndex]}">
                  ${w.config.labels[seriesIndex]}:
                  <b>${formatCurrency(series[seriesIndex])}</b>
                  <div class="caption">(${w.config.quantity[seriesIndex]}
                    ${
                      w.config.quantity[seriesIndex] === 1
                        ? w.config.dictionary.voucher
                        : w.config.dictionary.vouchers
                    })
                  </div>
                </div>`;
            }
          }
        }
      };
    },

    dataOfSelectedYear() {
      return this.chartData[this.selectedYear] ?? {};
    },

    orderedTypes() {
      return (
        VOUCHER_TYPES
          // show only voucher types with not null data in year selection
          .filter((type) => Object.keys(this.dataOfSelectedYear).includes(type))
          // summarize translation keys with same value
          .reduce((array, type) => {
            if (!array.some((el) => formatVoucherType(el) === formatVoucherType(type))) {
              array.push(formatVoucherType(type));
            }

            return array;
          }, [])
      );
    },

    orderedValues() {
      // summarize amounts and quantities of voucher types with same translation
      return this.orderedTypes.map((translatedType) =>
        Object.entries(this.dataOfSelectedYear).reduce(
          (object, typeKeyValuePair) => {
            if (translatedType === formatVoucherType(typeKeyValuePair[0])) {
              object['amount'] += typeKeyValuePair[1].amount;
              object['quantity'] += typeKeyValuePair[1].quantity;
            }
            return object;
          },
          { amount: 0, quantity: 0 }
        )
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchBalancingStatistic'])
  },

  async created() {
    this.loading = true;
    const { data, error } = await this.fetchBalancingStatistic();
    this.loading = false;

    if (error) {
      this.error = true;
      return;
    }
    this.chartData = data;
  }
};
</script>
