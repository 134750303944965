<template>
  <chart-card full-width title="dashboard.daysOverdueChart" :error="error" :no-data="hasNoData">
    <template #title-menu>
      <app-select
        v-if="!loading"
        id="days-overdue-chart__year"
        v-model="selectedYear"
        :items="yearItems"
        label="year"
        required
      />
    </template>

    <vue-apex-charts
      v-if="chartData && !loading"
      :options="chart.options"
      :series="chart.series"
      height="350"
    />
  </chart-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { ORANGE } from '@/modules/Dashboard/statics/chartColors';
import { NAMESPACE } from '@/modules/Dashboard/store';
import { mapActions } from 'vuex';
import ChartMixin from '@/mixins/ChartMixin';
import ChartCard from '@/modules/Dashboard/components/Charts/ChartCard';

export default {
  name: 'DaysOverdueChart',

  mixins: [ChartMixin],

  components: {
    ChartCard,
    VueApexCharts
  },

  computed: {
    chart() {
      return {
        series: [
          { name: this.selectedYear, data: this.dataOfSelectedYear.map((data) => data.days) }
        ],
        options: {
          ...this.axisChartDefaultOptions,
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          quantity: this.dataOfSelectedYear.map((data) => data.quantity),
          colors: ORANGE
        }
      };
    },
    dataOfSelectedYear() {
      return this.chartData[this.selectedYear] ?? [];
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchOverdueDaysStatistic'])
  },

  async created() {
    this.loading = true;
    const { data, error } = await this.fetchOverdueDaysStatistic();
    this.loading = false;

    if (error) {
      this.error = true;
      return;
    }

    this.chartData = data;
  }
};
</script>
